import React, { Component } from "react"
import Header from "../../components/base/Header"
import HeroVideo from "../../components/marketing/HeroVideo"
import Footer from "../../components/base/Footer"
import TNGHome from "../../images/tng_home.png"
import PartnerTanjung from "../../images/partner_tanjungmbpp.jpg"
import Whatsapp from "../../components/marketing/Whatsapp"
import { Button, Feed, Feeds } from "blixify-ui-web"
import { CheckIcon, HandThumbUpIcon, UserIcon } from "@heroicons/react/24/solid"
import { localeContent } from "../../utils/locale"
import { Language } from "../../store/reducers/utilsReducer"
import { Link } from "gatsby"
import SEO from "../../components/base/seo"
import moment from "moment"

class TanjungMBPPPage extends Component {
  renderFirstThursday = () => {
    const handleThursdayChecker = (date: any) => {
      let firstDay = date.clone().startOf("month")
      while (firstDay.weekday() !== 4) {
        firstDay.add(1, "days")
      }
      return firstDay
    }

    let currentDate = moment()
    let firstThursday = handleThursdayChecker(currentDate)
    if (currentDate.isAfter(firstThursday, "day")) {
      currentDate.add(1, "month")
      firstThursday = handleThursdayChecker(currentDate)
    }
    return firstThursday
  }

  render() {
    const language = Language.EN
    const feeds: Feed[] = [
      {
        iconClass: "bg-gray-400",
        iconType: <UserIcon className="h-5 w-5 text-white" />,
        content: localeContent(language).recycleUCO.leftContentCard1.title,
        step: "Step 1",
        addContent: (
          <p className="mt-2 text-base text-gray-500">
            {localeContent(language).recycleUCO.leftContentCard1.description}
          </p>
        ),
      },
      {
        iconClass: "bg-blue-500",
        iconType: <HandThumbUpIcon className="h-5 w-5 text-white" />,
        content: localeContent(language).recycleUCO.rightContentCard1.title,
        step: "Step 2",
        addContent: (
          <p className="mt-2 text-base text-gray-500">
            {localeContent(language).recycleUCO.rightContentCard1.description}
          </p>
        ),
      },
      {
        iconClass: "bg-green-500",
        iconType: <CheckIcon className="h-5 w-5 text-white" />,
        content: localeContent(language).partner.finalStepTitle,
        step: "Step 3",
        addContent: (
          <p className="mt-2 text-base text-gray-500">
            {localeContent(language).partner.finalStepDescription}
            <img src={PartnerTanjung} alt="" className="mt-4 w-56 h-auto" />
          </p>
        ),
      },
    ]

    return (
      <div className="bg-white">
        <Whatsapp />
        <SEO
          title="Arus Oil - Collaboration with Tanjung MBPP"
          description="Turn your used cooking oil into biodiesel thanks to a partnership between Arus Oil and Tanjung Bungah MBPP."
        />
        <Header page={"Index"} />
        <div className="flex flex-col items-center w-full">
          <div className="flex flex-col mx-5 mt-4 p-3 w-full max-w-5xl bg-primary-100 rounded-xl items-center">
            <div className="flex flex-col items-center max-w-lg">
              <img alt="" src={TNGHome} className="w-52 h-52 obj-cover" />
              <h2 className="text-xl font-extrabold text-arusgreen-800 text-center">
                Tanjung Bungah MBPP Food Court & Wet Market <br />
                Used Cooking Oil Recycling Center
              </h2>
              <p className="text-base font-bold text-gray-700 mb-2 text-center">
                {localeContent(language).partner.title}
              </p>

              <h3 className="mt-4 text-xl font-extrabold text-arusgreen-800">
                {localeContent(language).partner.dateDescription}
              </h3>
              <p className="text-3xl text-center font-semibold">
                {this.renderFirstThursday().format("DD MMMM YYYY")} <br />
                6pm-7pm
              </p>
              <p className="text-sm font-bold text-gray-700 mb-2">
                {localeContent(language).partner.dateSubtitle}
              </p>
              <Link to={"https://g.co/kgs/jZ8NLFS"}>
                <Button
                  text="Navigate To Location"
                  type="dark"
                  className="my-4"
                />
              </Link>
            </div>
          </div>
          <div className="flex flex-col mx-5 mt-4 p-3 w-full max-w-5xl bg-gray-100 rounded-xl items-center">
            <div className="flex flex-col items-center py-3">
              <h2 className="text-xl font-extrabold text-arusgreen-800 text-center">
                {localeContent(language).partner.recycleSteps}
              </h2>
              <Feeds content={feeds} bold />
            </div>
          </div>
        </div>
        <HeroVideo videoUrl="https://www.youtube.com/embed/fh8nOpUeaw0" />
        <Footer />
      </div>
    )
  }
}

export default TanjungMBPPPage
